<template>
  <!--用户信息-->
  <div class="app-container invoice-record-component">
    <div>
      <el-form ref="ruleForm" :model="ruleForm" label-width="80px">
        <el-row :gutter="20">
          <div v-if="administrators === 'platform'">
            <el-col :span="5">
              <el-form-item label="项目选择" prop="projectId">
                <el-select
                  style="width: 100%"
                  v-model="ruleForm.projectId"
                  @change="subsetCompany(ruleForm.projectId)"
                  placeholder="请选择项目"
                  clearable
                >
                  <el-option
                    v-for="(item, index) in tbProjectList"
                    :value="item.projectId"
                    :label="item.projectName"
                    :key="index"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="5"
              ><el-form-item label="公司选择" prop="enterpriseId">
                <el-select
                  style="width: 100%"
                  v-model="ruleForm.enterpriseId"
                  filterable
                  placeholder="请选择公司"
                >
                  <el-option
                    v-for="item in tbCompanyList"
                    :key="item.enterpriseId"
                    :value="item.enterpriseId"
                    :label="item.enterpriseName"
                    @click.native="changeCompany(item.id)"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </div>

          <el-col :span="5">
            <el-form-item label="关键词" prop="keyword">
              <el-input
                maxlength="50"
                placeholder="请输入关键词"
                v-model="ruleForm.keyword"
                :clearable="true"
              ></el-input
            ></el-form-item>
          </el-col>
          <el-col :span="5">
            <el-button type="primary" icon="el-icon-search" @click="tbSearch()"
              >搜索</el-button
            >
            <el-button>{{ this.$t("commons.export") }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      />
    </div>
  </div>
</template>

<script>
import {
  getTableProject,
  getTableCompany,
  adminCheckout,
  findCompanyPage,
  findEnterpriseList,
  tbExamine,
  tbDelete,
} from "@/api/ruge/gsPark/customerService/userInfos";
import finalTable from "@/components/FinalTable";
export default {
  components: {
    finalTable,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: false,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "sort", label: "序号", width: "" },
          { prop: "userName", label: "用户名称", width: "" },
          { prop: "phone", label: "联系电话", width: "" },
          { prop: "gender", label: "性别", width: "" },
          { prop: "enterpriseName", label: "企业名称", width: "500" },
          { prop: "employeeRoleName", label: "员工角色", width: "" },
          { prop: "creationDate", label: "创建时间", width: "" },
          { prop: "operation", label: "操作", width: "100" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {},
        // 表格内容配置
        detailConfig: {
          creationDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "toPass",
                fontSize: "14px",
                color: "#4374FA",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "通过",
                showProp: "auditStatus",
                showValue: "audit",
                show: true,
              },
              {
                actionType: "iconClick",
                eventName: "reject",
                fontSize: "14px",
                color: "#4374FA",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "驳回",
                showProp: "auditStatus",
                showValue: "audit",
                show: true,
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#F55F45",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "删除",
                showProp: "auditStatus",
                showValue: "deletable",
                show: true,
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {},
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      ruleForm: {
        projectId: null,
        enterpriseId: null,
        keyword: null,
      },
      tbProjectList: [],
      tbCompanyList: [],
      administrators: null,
      loadingFlag: false,
      requestParams: {
        current: 1,
        rowCount: 10,
        searchPhrase: null,
      },
    };
  },
  created() {
    this.findUserAdmin();
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  methods: {
    tbSearch() {
      this.requestParams.current = 1;
      this.dataset.pageVO.current = 1;
      this.getTableList();
    },
    findUserAdmin() {
      //获取登录用户权限 platform平台管理员   enterprise企业管理员
      adminCheckout({})
        .then((res) => {
          this.administrators = res === true ? "platform" : "enterprise";
          // this.administrators = res === true ? "enterprise" : "platform";
          if (this.administrators == "platform") {
            this.getProjectList();
          } else if (this.administrators == "enterprise") {
            this.getBindCompany();
          }
        })
        .catch(() => {});
    },
    getProjectList() {
      //获取项目集合
      getTableProject({})
        .then((res) => {
          if (res) {
            this.tbProjectList = res;
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    changeCompany(id) {
      //获取项目所属公司集合
      // this.enterpriseId = id;
    },
    getBindCompany() {
      findEnterpriseList()
        .then((res) => {
          if (res) {
            this.ruleForm.enterpriseId = res.enterpriseId;
            this.ruleForm.projectId = res.projectId;
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    subsetCompany(id) {
      getTableCompany({
        projectId: id,
      })
        .then((res) => {
          if (res) {
            this.tbCompanyList = res;
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    tableEventHandler(datas) {
      console.log("datas", datas);
      if (datas.type === "goSearch") {
        this.requestParams.current = 1;
        this.dataset.pageVO.current = 1;
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.dataset.pageVO.current = datas.params.current.page;
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "toPass":
            this.modifyData(datas.row, "toPass");
            break;
          case "reject":
            this.modifyData(datas.row, "reject");
            break;
          case "delete":
            this.modifyData(datas.row, "delete");
            break;
        }
      }
    },
    modifyData(row, type) {
      //弹窗标题文字
      let textPrompt = type == "toPass" || "reject" ? "提示" : "警告";
      let textType = null; //修改类型文字
      let subStatus = null; //通过、驳回修改状态
      if (type == "toPass" || type == "reject") {
        //通过、驳回
        textType = type == "toPass" ? "通过" : "驳回";
        subStatus = type == "toPass" ? 1 : 2;
      } else {
        //删除
        textType = "删除";
      }

      this.$confirm(`您确定要${textType}吗？`, textPrompt, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (type == "toPass" || type == "reject") {
            const params = {
              userId: row.userId,
              auditStatus: subStatus,
            };
            tbExamine(params).then((res) => {
              if (res.code === 200) {
                this.$message.success(`${textType}成功！`);
                this.getTableList();
              } else {
                this.$message.success(`${textType}失败！`);
              }
            });
          } else {
            tbDelete(row.userId).then((res) => {
              if (res === 1) {
                this.$message.success(`${textType}成功！`);
                this.getTableList();
              } else {
                this.$message.success(`${textType}失败！`);
              }
            });
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    getTableList() {
      this.loadingFlag = true;
      const params = {
        enterpriseId: this.ruleForm.enterpriseId,
        searchPhrase: this.ruleForm.keyword,
        projectId: this.ruleForm.projectId,
      };
      this.requestParams = { ...this.requestParams, ...params };
      console.log("this.requestParams", this.requestParams);
      findCompanyPage(this.requestParams)
        .then((res) => {
          res.rows.forEach((item, index) => {
            this.$set(item, "sort", index + 1);
            item.sex = item.sex == 1 ? "男" : "女";
            if (item.enterpriseId === "" || item.enterpriseId === null) {
              item.enterpriseName = item.positionName;
              item.enterpriseId = item.positionId;
            }
            if (this.administrators == "enterprise" && item.auditStatus == 0) {
              this.$set(item, "auditStatus", "audit"); //企业管理员且为待审核状态-可通过/驳回
            } else if (
              this.administrators == "enterprise" &&
              item.auditStatus == 1
            ) {
              this.$set(item, "auditStatus", "deletable"); //企业管理员且已通过-可删除
            }
          });
          console.log("res.rows", res.rows);
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch(() => {
          this.dataset.tableData = [];
          this.dataset.pageVO.total = 0;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
  },
};
</script>
<style scoped lang="less">
</style>