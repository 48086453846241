import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 查询项目一二三期
 * @param params
 */
export function getTableProject(params) {
  return request({
    url: envInfo.bgApp.basePath + '/tenant/project/list',
    method: 'get',
    params,
  });
}

/**
 * 查询项目所属公司集合
 * @param params
 */
export function getTableCompany(params) {
  return request({
    url: envInfo.bgApp.orgPath + '/enterprise/list',
    method: 'get',
    params,
  });
}

/**
 * 查询用户是否为平台管理员
 * @param params
 */
export function adminCheckout(params) {
  return request({
    url: envInfo.bgApp.orgPath + '/userInfo/adminCheckout',
    method: 'get',
    params,
  });
}

/**
 * 选择对应公司查询公司下人员信息
 * @param params
 */
export function findCompanyPage(params) {
  return request({
    url: envInfo.bgApp.orgPath + '/userInfo/page',
    method: 'get',
    params,
  });
}

/**
 * 选择对应公司查询公司下人员信息
 * @param params
 */
export function findEnterpriseList(params) {
  return request({
    url: envInfo.bgApp.orgPath + '/enterprise/enterpriseList',
    method: 'get',
    params,
  });
}

/**
 * 改变审核状态：通过、驳回
 * @param params
 */
export function tbExamine(params) {
  return request({
    url: envInfo.bgApp.orgPath + '/userInfo/toExamine',
    method: 'get',
    params,
  });
}

/**
 * 删除记录
 * @param params
 */
export function tbDelete(params) {
  return request({
    url: envInfo.bgApp.orgPath + '/userInfo/delete/' + params,
    method: 'delete',
  });
}
